<template>
  <div class="home ipxx-background-image">
    <ipxx-mypm-header />
    <div class="flex-grow-1">
      <div class="nav">
        <router-link to="/">
          Home
        </router-link>
        <router-link to="/tests">
          Test Page
        </router-link>
        <router-link :to="`/start/${azureProviderId}`">
          Azure Login
        </router-link>
        <b-btn
          variant="link"
          @click="testConnectAccount"
        >
          Test Connect mypm Account
        </b-btn>
        <b-btn
          variant="link"
          @click="testEmailNotVerified"
        >
          Test Email Not Verified
        </b-btn>
        <router-link to="/email/verify">
          Email Verify
        </router-link>
        <router-link to="/password/reset">
          Password Reset
        </router-link>
      </div>
    </div>
    <ipxx-mypm-footer />
  </div>
</template>

<script>
import IpxxMypmFooter from '@/components/MypmFooter';
import IpxxMypmHeader from '@/components/MypmHeader';

export default {
  name: 'HomeView',
  components: {
    IpxxMypmFooter,
    IpxxMypmHeader,
  },
  computed: {
    azureProviderId() {
      return this.$config.providers.azure;
    },
  },
  methods: {
    testConnectAccount() {
      this.$http.get('/setNoAssignedAccountError')
        .then(() => {
          this.$router.push('/start/100');
        })
        .catch((error) => {
          this.$logger.log(error);
        });
    },
    testEmailNotVerified() {
      this.$http.get('/setEmailNotVerifiedError')
        .then(() => {
          this.$router.push('/start/100');
        })
        .catch((error) => {
          this.$logger.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
.home {
  .nav {
    padding: 30px;

    a, .btn-link {
      color: $ipxx-dark-grey;
      padding: 0 0.75rem;
      &:not(:last-child) {
        border-radius: 0;
        border-right: 1px solid $ipxx-dark-grey;
      }

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }
}
</style>
